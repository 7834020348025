<template>
    <div class="row">
        <div class="col-12">
            <div class="card card m-b-0">
                <!-- .chat-row -->
                <div class="chat-main-box">
                    <!-- .chat-left-panel -->
                    <div class="chat-left-aside">
                        <div class="open-panel">
                            <i class="ti-angle-right"></i>
                        </div>
                        <div class="chat-left-inner form-horizontal" style="height: 3px;">
                            <div class="form-material">
                                <div class="input-group searchbox">
                                    <input type="text" class="form-control" id="uname" placeholder="Pencarian..."
                                        v-model="userUiFlag.searchTerm" @input="isTyping = true">
                                    <div class="input-group-addon">
                                        <i class="ti-search"></i>
                                    </div>
                                </div>
                            </div>
                            <div id="left-inner-scrollbar" data-scrollbar="true" tabindex="1"
                                style="overflow: hidden; outline: none;">
                                <div class="scroll-content">
                                    <ul class="chatonline style-none">
                                        <li v-for="item in userList" :key="item._id">
                                            <router-link v-bind:class="{ active: item.selected }"
                                                :to="{ name: 'user', params: {id: encodeURIComponent(item._id)}}">
                                                <span class="chat-info"> <span class="user-name">{{ item.name }}</span>
                                                    <small class="text-default">
                                                        <!-- <div class="chat-content-thumb" >
                                                        </div> -->
                                                        <div v-if="isAdmin">
                                                            <span class="badge badge-pill badge-success text-white mr-1" v-for="(role, index) in item.securityRoleIds" :key="index">
                                                                <v-icon style="font-size:10px !important" class="text-white" left>mdi-account</v-icon> 
                                                                {{ role }}
                                                            </span>
                                                        </div>
                                                    </small>
                                                </span>
                                                <span v-if="userDetailFlag.isLoading && item.selected == true"><i
                                                        class="fa fa-circle-o-notch fa-spin"></i> </span>
                                                <span class="account-status"><i v-if="item.accountStatus == 'ACTIVE'"
                                                        title="Pengguna Aktif" class="mdi mdi-check-circle" style="color:green;"></i>
                                                    <i v-if="item.accountStatus == 'VOID'" title="Pengguna Nonaktif"
                                                        class="mdi mdi-minus-circle" style="color:red;"></i></span>
                                            </router-link>
                                        </li>
                                        <li id="user-loader-indicator" v-if="userUiFlag.isLoading">
                                            <span class="chat-info"> <span class="user-info">Load More...</span>
                                                <small class="text-default">
                                                    <div class="chat-content-thumb"></div>
                                                </small>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                                <div class="scrollbar-track scrollbar-track-x" style="display: none;">
                                    <div class="scrollbar-thumb scrollbar-thumb-x"
                                        style="width: 329px; transform: translate3d(0px, 0px, 0px);"></div>
                                </div>
                                <div class="scrollbar-track scrollbar-track-y" style="display: block;">
                                    <div class="scrollbar-thumb scrollbar-thumb-y"
                                        style="height: 20px; transform: translate3d(0px, 0px, 0px);"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- .chat-left-panel -->
                    <!-- .chat-right-panel -->
                    <div class="chat-right-aside">
                        <div class="chat-main-header">
                        </div>
                        <div class="chat-rbox static-content">
                            <div class="wrapp-chat-content" data-scrollbar="true" tabindex="1"
                                style="overflow: hidden; outline: none;">
                                <div class="scroll-content">
                                    <div class="contact-add">
                                        <form class="form-horizontal form-material">
                                            <button id="btn-new" type="button"
                                                class="btn btn-default waves-effect waves-light m-t-10 btn-new-contact"
                                                @click="$router.push('/user/')">Buat Pengguna Baru</button>
                                            <div class="wrapp-input-contact">
                                                <div class="row">
                                                    <label for="f-user-full-name"
                                                        class="col-sm-12 col-md-3 text-right control-label col-form-label">Nama
                                                        Lengkap</label>
                                                    <div class="col-sm-12 col-md-6 pt-0">
                                                        <input v-model="user.name" type="text" class="form-control"
                                                            id="f-user-full-name" >
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <label for="f-user-id"
                                                        class="col-sm-12 col-md-3 text-right control-label col-form-label">ID
                                                        Pengguna</label>
                                                    <div class="col-sm-12 col-md-6 pt-0">
                                                        <input v-if="user.mode == 'edit'" disabled v-model="user.userId"
                                                            type="text" class="form-control" id="f-user-id"
                                                            placeholder="ID Pengguna">
                                                        <input v-else v-model="user.generatedId" type="text"
                                                            class="form-control" id="f-user-id"
                                                            >
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <label for="f-user-email"
                                                        class="col-sm-12 col-md-3 text-right control-label col-form-label">EMail</label>
                                                    <div class="col-sm-12 col-md-6 pt-0">
                                                        <input v-model="user.email" type="text" class="form-control"
                                                            id="f-user-email">
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <label for="f-password"
                                                        class="col-sm-12 col-md-3 text-right control-label col-form-label">Kata
                                                        Sandi</label>
                                                    <div class="col-sm-12 col-md-5 pt-0">
                                                        <input v-model="user.password" type="password"
                                                            class="form-control" id="f-password">
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <label for="f-password"
                                                        class="col-sm-12 col-md-3 text-right control-label col-form-label">Status
                                                        Pengguna</label>
                                                    <div class="col-sm-12 col-md-5"
                                                        style="">
                                                        <!-- <template v-for="(item, index) in availableRoles"> -->
                                                        <!-- <input type="checkbox" value="ACTIVE" v-model="user.accountStatus"> Aktif<br /> -->
                                                        
                                                        <!-- tambahin class yang opacity = 0 *gak tau work atau gak di server -->
                                                        <input type="checkbox" id="accStatus" 
                                                            v-model="user.accountStatus" true-value="ACTIVE"
                                                            false-value="VOID" class="filled-in chk-col-indigo">
                                                        <label class="ml-2 mr-2" for="accStatus">Aktif</label>
                                                    </div>
                                                    <!-- </template> -->
                                                </div>
                                                <div class="row">
                                                    <label for="f-password"
                                                        class="col-sm-12 col-md-3 text-right control-label col-form-label">Hak
                                                        Akses</label>
                                                    <div class="col-sm-12 col-md-5">
                                                        <template v-for="(item, index) in availableRoles">
                                                            <input type="checkbox" v-bind:value="item.id" :key="index"
                                                                v-bind:id="item.id" class="filled-in chk-col-indigo"
                                                                v-model="user.securityRoleIds">
                                                            <label :key="index + '-label'" v-bind:for="item.id" class="ml-2 mr-2">
                                                                {{ item.name}}
                                                            </label>
                                                        </template>
                                                    </div>
                                                </div>
                                                <br>
                                                <ErrorMessage :error="userDetailFlag.error" />
                                            </div>
                                            <div class="button-wrapp">
                                                        <button id="btn-save"
                                                            class="btn btn-primary waves-effect waves-light m-t-10 pull-right"
                                                            @click="saveUser"> <i v-if="userDetailFlag.isSaving"
                                                                class="fa fa-circle-o-notch fa-spin"></i>&nbsp;Simpan</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div class="scrollbar-track scrollbar-track-x" style="display: none;">
                                    <div class="scrollbar-thumb scrollbar-thumb-x"
                                        style="width: 661px; transform: translate3d(0px, 0px, 0px);"></div>
                                </div>
                                <div class="scrollbar-track scrollbar-track-y" style="display: block;">
                                    <div class="scrollbar-thumb scrollbar-thumb-y"
                                        style="height: 445.289px; transform: translate3d(0px, 0px, 0px);"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SuccessNotif />
    </div>
</template>
<script>
    import axios from 'axios'
    import Scrollbar from 'smooth-scrollbar'
    import ErrorMessage from '../components/ErrorMessage'
    import MainLayout from "../layouts/MainLayout"
    import SuccessNotif from "../components/SuccessNotif"
    import {
        mapState
    } from 'vuex'

    const $ = require("jquery")
    const debounce = require('lodash.debounce')
    const {
        commonpagesetup,
        mainuisetup
    } = require('../utils/ui-setup')

    const USER_ROW_PER_PAGE = 25
    var userScrollBar = null

    export default {
        computed: {
            ...mapState({
                sessionInfo: state => state.session.sessionInfo
            })
        },
        data: function () {
            return {
                availableRoles: [{
                    id: "bippotalk_admin",
                    name: "Administrator"
                }, {
                    id: "messages_admin",
                    name: "Operator"
                }],
                userList: [],
                userUiFlag: {
                    error: {},
                    currentPage: 1,
                    searchTerm: '',
                    selectedUser: '',
                    hasMoreData: true,
                    isLoading: false
                },
                user: {
                    mode: '',
                    userId: '',
                    name: '',
                    password: '',
                    email: '',
                    generatedId: '',
                    accountStatus: 'VOID',
                    securityRoleIds: []
                },
                userDetailFlag: {
                    error: {},
                    isLoading: false,
                    isSaving: false
                },
                isTyping: false,
                isAdmin: false
            }
        },
        watch: {
            'user.name': function (val, old) {
                if (old == '' || this.user.generatedId == old.toLowerCase().replace(/[\-\s]/g, "_").replace(/[^a-zA-Z0-9_]/g,"")) {
                    this.user.generatedId = val.replace(/[\-\s]/g, "_").replace(/[^a-zA-Z0-9_]/g, "").toLowerCase();
                }
            },
            'userUiFlag.searchTerm': debounce(function () {
                this.isTyping = false;
            }, 1000),
            $route: async function (to, from) {
                if (this.$helpers.isBlank(to.params.id)) {
                    this.resetToEmptyForm();
                } else if (to.params.id != from.params.id) {
                    this.selectUser(decodeURIComponent(to.params.id));
                }
            },
            sessionInfo: function (val) {
                if (!this.$helpers.isBlank(val) && val.isLoggedIn) {
                    this.loadInitialData();
                }
            },
            isTyping: function (value) {
                if (!value) {
                    this.loadUser();
                }
            }
        },
        methods: {
            getCurrUserRole: async function() {
                try {
                    var { data } = await axios.get(process.env.VUE_APP_API_URL + '/user/' + this.sessionInfo.userId);
                    console.log(data, "uyam");
                    this.isAdmin = data.securityRoleIds.includes('bippotalk_admin')

                } catch (error) {
                    console.log('check user role', error);      
                }
            },
            selectUser: async function (userId) {
                this.userList.forEach(element => {
                    if (element._id == userId) {
                        element.selected = true
                    } else {
                        element.selected = false
                    }
                })
                this.userDetailFlag.error = {}
                this.userDetailFlag.isLoading = true;
                try {
                    var resp = await axios.get(process.env.VUE_APP_API_URL + '/user/' + userId);
                    var jsonData = resp.data;
                    this.user = {
                        mode: 'edit',
                        userId: jsonData._id,
                        name: jsonData.name,
                        password: '',
                        email: jsonData.email,
                        accountStatus: jsonData.accountStatus,
                        securityRoleIds: jsonData.securityRoleIds
                    }
                } catch (error) {
                    this.userDetailFlag.error = this.$helpers.createError(error)
                }
                this.userDetailFlag.isLoading = false
            },
            loadUser: async function () {
                this.userList = []
                this.userUiFlag.currentPage = 1
                this.loadUserOnPage()
            },
            loadMoreUsers: async function () {
                this.userUiFlag.currentPage++
                this.loadUserOnPage()
            },
            loadUserOnPage: async function () {
                this.userUiFlag.error = {}
                this.userUiFlag.hasMoreData = true;
                this.userUiFlag.isLoading = true;

                try {
                    var resp = await axios.get(process.env.VUE_APP_API_URL + '/user/', {
                        params: {
                            page: this.userUiFlag.currentPage,
                            sort: this.userUiFlag.sortOrder,
                            term: this.userUiFlag.searchTerm,
                            size: USER_ROW_PER_PAGE
                        }
                    });
                    this.userUiFlag.isLoading = false
                    var jsonData = resp.data
                    this.userUiFlag.hasMoreData = jsonData.page.number < jsonData.page.totalPages;
                    this.userList.splice(this.userList.length, 0, ...jsonData.content);
                } catch (error) {
                    this.userList.error = this.$helpers.createError(error)
                }
                this.userUiFlag.isLoading = false
            },
            loadInitialData: async function () {
                await this.getCurrUserRole()
                await this.loadUser();
                if (this.$route.params.id != null) {
                    this.selectUser(decodeURIComponent(this.$route.params.id));
                }
            },
            saveUser: async function (event) {
                event.preventDefault()
                var errors = []
                if (this.$helpers.isBlank(this.user.userId) && this.$helpers.isBlank(this.user.generatedId)) {
                    errors.push("'ID Pengguna' tidak boleh kosong")
                }
                if (this.$helpers.isBlank(this.user.name)) {
                    errors.push("'Nama' tidak boleh kosong")
                }
                if (this.$helpers.isBlank(this.user.email)) {
                    errors.push("'EMail' tidak boleh kosong")
                }
                if (this.$helpers.isBlank(this.user.password) && this.user.mode == "new") {
                    errors.push("'Password' tidak boleh kosong")
                }
                if (this.user.securityRoleIds.length == 0) {
                    errors.push('Pilihan minimal 1 peran (Administrator dan/atau Operator)')
                }

                if (errors.length > 0) {
                    this.userDetailFlag.error = this.$helpers.createErrorFromList(errors)
                } else {
                    var data = {
                        name: this.user.name,
                        password: this.user.password,
                        email: this.user.email,
                        accountStatus: this.user.accountStatus,
                        securityRoleIds: this.user.securityRoleIds
                    }

                    this.userDetailFlag.isSaving = true
                    try {
                        if (this.user.mode == 'edit') {
                            data._id = this.user.userId
                            await axios.put(process.env.VUE_APP_API_URL + '/user/', data);
                        } else {
                            data._id = this.user.generatedId
                            await axios.post(process.env.VUE_APP_API_URL + '/user/', data);
                        }
                        this.$bvToast.show('success-notif')
                    } catch (error) {
                        this.userDetailFlag.error = this.$helpers.createError(error)
                    }
                    this.userDetailFlag.isSaving = false
                }
            },
            resetToEmptyForm: function () {
                this.userList.forEach(element => {
                    element.selected = false
                })
                this.user = {
                    mode: 'new',
                    userId: '',
                    name: '',
                    password: '',
                    email: '',
                    generatedId: '',
                    accountStatus: 'VOID',
                    securityRoleIds: []
                }
            }
        },
        created: function () {
            this.$emit('update:layout', MainLayout);
        },
        mounted: async function () {
            Scrollbar.initAll();
            userScrollBar = Scrollbar.get($('#left-inner-scrollbar').get()[0]);

            var self = this;
            userScrollBar.addListener((status) => {
                if (this.userUiFlag.hasMoreData && status.offset.y == userScrollBar.limit.y) {
                    setTimeout(() => self.loadMoreUsers(), 500);
                }
            });
            if (!this.$helpers.isBlank(this.sessionInfo) && this.sessionInfo.isLoggedIn) {
                await this.loadInitialData();
            }
            try {
                mainuisetup();
                commonpagesetup("message", this.$route.params.id);
            } catch (err) {
                console.log(err);
            }
        },
        components: {
            ErrorMessage,
            SuccessNotif
        }
    }
</script>